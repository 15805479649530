




































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DateTimePicker from '@/components/DateTimePicker.vue';
import AdminVisitTable from '@/components/tables/AdminVisitTable.vue';
import AdminVisitForm from '@/components/forms/AdminVisitForm.vue';
import ListComponent from '@/components/ListComponent.vue';

import {
  dispatchCreateNote,
  dispatchCreateRequisition, dispatchCreateRequisitionComment,
  dispatchCreateVisit,
  dispatchDeleteNote, dispatchDeleteRequisitionComment,
  dispatchDeleteVisit, dispatchGetCategories,
  dispatchGetPatientById,
  dispatchGetPatientsForList,
  dispatchGetRequisitionById,
  dispatchGetUsersForList,
  dispatchUpdateNote,
  dispatchUpdateRequisition, dispatchUpdateRequisitionComment,
} from '@/store/crud/actions';
import AppComponent from '@/mixins/ComponentMixin.vue';
import {ICreateRequisition, IRequisition, IUpdateRequisition} from '@/interfaces/requisitions';
import {IPatient} from '@/interfaces/patients';
import moment from 'moment';
import {ICreateVisit, IVisit} from '@/interfaces/visits';
import {readUserProfile} from '@/store/main/getters';

import TestResultComments from '@/components/TestResultComments.vue';
import { INote, INoteInput } from '@/interfaces/notes';
// ts-ignore - ToDO: fix on TSLint -> ESLint move
import { intersectionWith, unionBy } from 'lodash';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import { readCategories } from '@/store/crud/getters';
import { ICategory } from '@/interfaces/categories';
import { IBiomarkerResult } from '@/interfaces/biomarkerResults';
import {ICriticalResult} from '@/interfaces/criticals';
import {IRequisitionComment, IRequisitionCommentInput} from '@/interfaces/requisitionComments';

@Component({
  components: {
    DateTimePicker,
    AdminVisitForm,
    TestResultComments,
    AdminVisitTable,
    ListComponent,
    ConfirmationModal,
  },
})
export default class AdminRequisition extends AppComponent {

  public get fetching() {
    return this.fetchingPatient || this.fetchingRequisition || this.fetchingUsers;
  }

  public get isEdit() {
    return !!this.editingRequisition;
  }

  get notes() {
    const notes = (this.editingRequisition?.listOfPhysicianNotes || []).map((note) => {
      if (note.category) {
        return note;
      }
      return {
        ...note,
        category: !note.category
          ? {
              categoryName: 'Summary',
            }
          : note.category,
      };
    });
    return [...notes].sort((a, b) => {
      if ((a.updatedAt || a.createdAt) > (b.updatedAt || b.createdAt)) {
        return -1;
      }
      return 1;
    });
  }

  get groupedNotes() {
    let patientSex;
    if (this.patientProfile && typeof this.patientProfile !== 'string') {
      patientSex = (this.patientProfile?.biologicalSex || '').toLowerCase();
    }
    const categories = readCategories(this.$store);
    if (!categories.length) {
      return [...this.notes].sort(this.sortNoteByCategoryName);
    }
    const results = this.testResults;
    const availableCategories = intersectionWith(categories, results, (category, result) => {
      if (patientSex === 'male' && (category.categoryName || '').toLowerCase() === 'female health') {
        return false;
      }
      if (patientSex === 'female' && (category.categoryName || '').toLowerCase() === 'male health') {
        return false;
      }
      if (category.biomarkers) {
        return category.biomarkers.some((biomarker) => biomarker.name === result.biomarker?.name);
      }
      return false;
    });
    availableCategories.push({
      categoryName: 'Summary',
    });
    const notes = this.notes.map((note) => {
      if (note.category) {
        return note;
      }
      return {
        ...note,
        category: {
          categoryName: 'Summary',
        },
      };
    });
    const emptyNotes = availableCategories.map(this.categoryToEmptyNote);
    return unionBy(notes, emptyNotes, 'category.categoryName').sort(this.sortNoteByCategoryName);
  }

  /**
   * checks if the submit button should be enabled
   * according to business rules
   */
  get submitEnabled() {
    let enabled = true;
    if (!this.patientProfile || !this.patientProfile.id) {
      enabled = false;
    }
    return enabled;
  }

  /**
   * Gets patient full name to display in the view
   */
  get patientFullName() {
    if (this.patientProfile) {
      return `${this.patientProfile.fname} ${this.patientProfile.lname}`.trim();
    } else {
      return '';
    }
  }

  /**
   * gets the current user id
   */
  get currentUserId() {
    return readUserProfile(this.$store)?.id;
  }

  public get getAdminComments() {
    const comments: IRequisitionComment[] = [];

    if (this.editingRequisition && this.editingRequisition.requisitionComments?.length > 0) {
      comments.push(...this.editingRequisition.requisitionComments);
    }

    comments.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      return dateA.getTime() - dateB.getTime();
    });

    return comments;
  }
  @Prop({type: String, required: false}) public requisitionPatientId: string;
  @Prop({type: String, required: false}) public requisitionId: string;

  public state: string = '';
  public assignedPatient: string = '';
  public loading: boolean = false;
  public patientProfile: any = {};
  public needDataUpdate: boolean = false;
  public referringPhysicianId: string | null = null;
  public reviewingPhysicianId: string | null = null;
  public reviewed: boolean = false;
  public patientNotes: string[] = [];
  public nextVisitCriticalResult: ICriticalResult | null | undefined = null;
  public dateTimeConfirmedLocalTime: string = '';
  public dateTimeConfirmedUTC: string | null = null;
  public dateTimeReviewedLocalTime: string = '';
  public dateTimeReviewedUTC: string | null = null;
  public readyForConfirmation: boolean = false;
  public allResultsAvailable: boolean = false;
  public showVisitForm: boolean = false;
  public assignedVisits: IVisit[] = [];
  public newPhysicianComment: string = '';

  public editingRequisition: IRequisition | null = null;
  public editingVisit: IVisit | null = null;
  public timezone = this.getCurrentTimezone();
  // fetching flags
  public fetchingPatient: boolean = false;
  public fetchingRequisition: boolean = false;
  public fetchingUsers: boolean = false;

  public dialog = {};

  public testResults = [] as IBiomarkerResult[];

  // edit properties
  public confirmation: boolean = false;
  public changedNotes = new Set<string>([]);
  private dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

  public async processData() {
    this.testResults = [] as IBiomarkerResult[];
    const biomarkerResultsFromVisits: IBiomarkerResult[] = [];
    if (
      this.editingRequisition &&
      this.editingRequisition.visits &&
      this.editingRequisition.visits.length > 0
    ) {
      // get all biomarker results from all visits
      for (const visit of this.editingRequisition.visits as IVisit[]) {
        if (
          visit.biomarkerResults &&
          Array.isArray(visit.biomarkerResults) &&
          visit.biomarkerResults.length > 0
        ) {
          biomarkerResultsFromVisits.push(...(visit.biomarkerResults as IBiomarkerResult[]));
        } else if (
          visit.biomarkerResults &&
          !Array.isArray(visit.biomarkerResults) &&
          Object.keys(visit.biomarkerResults).length > 0
        ) {
          biomarkerResultsFromVisits.push(visit.biomarkerResults as IBiomarkerResult);
        }
      }

      this.nextVisitCriticalResult = this.editingRequisition?.visits.sort((a, b) => {
        const dateA = new Date(a.visitDate);
        const dateB = new Date(b.visitDate);
        return dateA.getTime() - dateB.getTime();
      })[this.editingRequisition?.visits.length - 1].criticalResult;

      if (biomarkerResultsFromVisits && biomarkerResultsFromVisits.length > 0) {
        this.testResults = biomarkerResultsFromVisits;
      }

    }
    return Promise.resolve();
  }

  public humanizeDate(date: string | Date, format?: string) {
    return moment.utc(date)
      .tz(this.timezone)
      .format(format || this.dateTimeFormat);
  }

  /**
   * mounted hook
   * inits the view by calling async Configure View
   */
  public mounted() {
    this.configureView();
  }

  /**
   * Configures the view
   * by checking if it is creation, edit
   * and fetching data if needed
   */
  public async configureView() {
    await this.fetchData();
    if (this.isEdit) {
      this.resetEditMode();
    } else {
      this.reset();
    }
  }

  /**
   * Fetches all the data required for the view
   */
  public async fetchData() {
    this.fetchingPatient = true;
    this.fetchingRequisition = true;
    this.fetchingUsers = true;
    // force the dom to update before fetching data
    await this.$nextTick();
    await this.fetchRequisitionData();
    await this.fetchPatientData();
    await this.fetchUsers();

    return Promise.resolve();
  }

  public async fetchUsers() {
    await dispatchGetUsersForList(this.$store);
    this.fetchingUsers = false;
    await this.$nextTick();
    return Promise.resolve();
  }

  /**
   * Fetches the patient data from the server
   */
  public async fetchPatientData() {
    // we dont allow changing patient in edit mode
    if (!this.isEdit) {
      await dispatchGetPatientsForList(this.$store);
    }
    /* Fetches patient data if the patient id is passed from a previous step
      or if the patientId is set from a previous step and we dont have the full
      patient data yet
    */
    if (
      !this.isEdit &&
      !this.isEmpty(this.requisitionPatientId) &&
      (this.isEmpty(this.patientProfile) || this.needDataUpdate)
    ) {
      this.patientProfile = await dispatchGetPatientById(this.$store, this.requisitionPatientId);
    } else if (this.isEdit && (this.isEmpty(this.patientProfile) || this.needDataUpdate)) {
      this.patientProfile =
        this.editingRequisition && this.editingRequisition.patient
          ? this.editingRequisition.patient
          : {};
      this.assignedPatient = this.patientProfile.id;
    }
    this.fetchingPatient = false;
    await this.$nextTick();
    return Promise.resolve();
  }

  /**
   * Fetches the requisition data from the server
   */
  public async fetchRequisitionData() {
    // fetches requisition data only if requisitionId is passed
    // and we dont have it from previous step
    if (
      !this.isEmpty(this.requisitionId) &&
      (this.isEmpty(this.editingRequisition) || this.needDataUpdate)
    ) {
      const result = await dispatchGetRequisitionById(this.$store, this.requisitionId);
      if (result) {
        this.editingRequisition = result as IRequisition;
      } else {
        // error while fetching requisition, we proceed to create a new one
        this.toast('Error while fetching requisition', true);
      }
    }
    this.fetchingRequisition = false;
    await this.$nextTick();
    await this.processData();
    return Promise.resolve();
  }

  /*
    It resets the screen inputs by reseting the models
    that the template uses.
    This reset is exclusively for the screen inputs.
  */
  public reset() {
    this.state = '';
    this.assignedPatient = this.requisitionPatientId ? this.requisitionPatientId : '';
    this.referringPhysicianId = null;
    this.reviewed = false;
    this.reviewingPhysicianId = null;
    this.patientNotes = [];
    this.readyForConfirmation = false;
    this.allResultsAvailable = false;
    this.confirmation = false;
    this.dateTimeConfirmedLocalTime = '';
    this.dateTimeConfirmedUTC = null;
    this.dateTimeReviewedLocalTime = '';
    this.dateTimeReviewedUTC = null;
    this.$validator.reset();
  }

  /**
   * Resets the form in edit mode
   */
  public resetEditMode() {
    if (this.editingRequisition) {
      this.state = this.editingRequisition.requisitionState as string;
      this.fetchPatientData();
      this.confirmation = this.editingRequisition.confirmation || false;
      this.reviewed = this.editingRequisition.reviewed || false;
      this.referringPhysicianId = this.editingRequisition.referringPhysicianId || null;
      this.reviewingPhysicianId = this.editingRequisition.reviewingPhysicianId || null;
      this.patientNotes = this.editingRequisition.patientNotes
        ? [...this.editingRequisition.patientNotes]
        : [];
      this.assignedVisits = (this.editingRequisition.visits as IVisit[]) || [];
      this.readyForConfirmation = this.editingRequisition.readyForConfirmation || false;
      this.allResultsAvailable = this.editingRequisition.allResultsAvailable || false;
      this.dateTimeConfirmedUTC = (this.editingRequisition?.dateTimeConfirmed as string) ?? '';
      this.dateTimeReviewedUTC = (this.editingRequisition?.dateTimeReviewed as string) ?? '';

      this.dateTimeConfirmedLocalTime = this.editingRequisition.dateTimeConfirmed
        ? (this.humanizeDate(this.editingRequisition.dateTimeConfirmed) as string)
        : '';
      this.dateTimeReviewedLocalTime = this.editingRequisition.dateTimeReviewed
        ? (this.humanizeDate(this.editingRequisition.dateTimeReviewed) as string)
        : '';
    }
    this.$validator.reset();
  }

  /**
   * Creates a new visit draft and passes it to the form
   * to be edited.
   * Updates the current changes of the requisition
   * It turns on the form flag
   */
  public async onCreateVisitClicked() {
    try {
      if (
        this.patientProfile &&
        this.patientProfile.id &&
        this.editingRequisition &&
        this.editingRequisition.id
      ) {
        const visitBlueprint: ICreateVisit = {
          patientId: this.patientProfile.id,
          state: this.editingRequisition.requisitionState || '',
        };
        const createdVisit = await this.createVisit(visitBlueprint);
        if (createdVisit) {
          this.editVisit(createdVisit);
        } else {
          throw new Error('Error while creating visit');
        }
      } else {
        throw new Error('Patient or requisition not found');
      }
    } catch (error) {
      this.toast('Error while creating visit', true);
      return;
    }
  }

  /**
   * removes a comment from the array of comments
   */
  public async physicianCommentRemoved(item) {
    if (!item) {
      return;
    }
    let result: boolean = false;
    result = await dispatchDeleteRequisitionComment(this.$store, {id: item.id});

    if (result) {
      this.toast('Comment deleted!', false);
      if (this.editingRequisition) {
        const toDeleteIndex = this.editingRequisition.requisitionComments.findIndex(
          (i) => i.id === item.id,
        );
        if (toDeleteIndex !== -1) {
          this.editingRequisition.requisitionComments.splice(toDeleteIndex, 1);
        }
      }
    } else {
      this.toast('error deleting comment', true);
    }
  }

  /**
   * updates a comment from the array of comments
   */
  public async physicianCommentUpdated(comment, updatedCommentValue) {
    if (!comment) {
      return;
    }

    let result: boolean = false;
    result = await dispatchUpdateRequisitionComment(this.$store, { id: comment.id, comment: updatedCommentValue });

    this.setAppLoading(false);

    if (result) {
      this.toast('Comment saved!', false);
      if (this.editingRequisition) {
        const toUpdateIndex = this.editingRequisition.requisitionComments.findIndex(
          (i) => i.id === comment.id,
        );
        if (toUpdateIndex !== -1) {
          this.editingRequisition.requisitionComments[toUpdateIndex].comment =
            updatedCommentValue;
        }
      }
    } else {
      this.toast('error saving comment', true);
    }
  }

  /**
   * adds a new comment to the array of comments
   * with an special format
   */
  public async createPhysicianComment() {
    const commentToCreate = this.newPhysicianComment;
    const resultComments = {} as IRequisitionCommentInput;
    if (!commentToCreate) {
      this.toast('Please enter a comment', true);
      return;
    }

    resultComments.comment = commentToCreate;
    resultComments.requisitionId = this.editingRequisition?.id || '';

    const result = await dispatchCreateRequisitionComment(this.$store, resultComments);

    if (result) {
      this.toast('Comment created!', false);
      if (this.editingRequisition) this.editingRequisition.requisitionComments.push(result.createRequisitionComment);
      this.newPhysicianComment = '';
    } else {
      this.toast('error creating comment', true);
    }

    return Promise.resolve(resultComments);
  }

  @Watch('dateTimeConfirmedLocalTime')
  public onDateTimeConfirmedChanged(newValue: string) {
    if (newValue && newValue.length) {
      this.dateTimeConfirmedUTC = moment
        .tz(newValue, this.dateTimeFormat, this.timezone)
        .utc()
        .format(this.dateTimeFormat) as string;
    } else {
      this.dateTimeConfirmedUTC = '';
    }
  }

  @Watch('dateTimeReviewedLocalTime')
  public onDateTimeReviewedChanged(newValue: string) {
    if (newValue && newValue.length) {
      this.dateTimeReviewedUTC = moment
        .tz(newValue, this.dateTimeFormat, this.timezone)
        .utc()
        .format(this.dateTimeFormat) as string;
    } else {
      this.dateTimeReviewedUTC = '';
    }
  }

  /**
   * watches changes in confirmation flag
   * and resets the values that are linked with it
   */
  @Watch('confirmation')
  public onConfirmationChange(val: boolean) {
    if (!val) {
      this.referringPhysicianId = null;
    }
  }

  /**
   * watches changes in reviewed flag
   * and resets the values that are linked with it
   */
  @Watch('reviewed')
  public onReviewedChange(val: boolean) {
    if (!val) {
      this.reviewingPhysicianId = null;
    }
  }

  /**
   * sets a new patient profile based on the
   * selected patient from the list
   */
  @Watch('assignedPatient')
  public async onAssignedPatientChange(newValue: string) {
    if (newValue && newValue.length > 0) {
      this.patientProfile = await dispatchGetPatientById(this.$store, newValue);
    } else {
      this.patientProfile = {};
    }
  }

  /**
   * Creates a new requisition,
   * if the result is successful, it redirects to the edit requisition
   * path with the requisition id and more fields are displayed
   */
  public async createRequisition() {
    if (await this.$validator.validateAll()) {
      const createRequisitionPayload: ICreateRequisition = {
        patientId: this.patientProfile.id,
        requisitionState: this.state || '',
        confirmation: false, // important for BE to avoid checking with null
        reviewed: false, // important for BE to avoid checking with null
        allResultsAvailable: false, // important for BE to avoid checking with null
        readyForConfirmation: false, // important for BE to avoid checking with null
      };

      this.setAppLoading(true);
      const result = await dispatchCreateRequisition(this.$store, createRequisitionPayload);
      if (result) {
        const createdRequisition = result as IRequisition;
        // successfully created requisition
        if (
          createdRequisition &&
          createdRequisition.id &&
          createdRequisition.patient &&
          (createdRequisition.patient as IPatient).id
        ) {
          // response should always come with patient data
          this.editingRequisition = createdRequisition;

          // we already have the patient data but since we are getting a fresh copy
          // lets update the patient profile with the new data
          this.patientProfile = createdRequisition.patient;
          this.$router.push({
            name: 'main-admin-requisitions-edit',
            params: {
              requisitionId: createdRequisition.id,
              requisitionPatientId: (createdRequisition.patient as IPatient).id!,
            },
          });
          // this.$router.('/main/admin/requisitions/edit/' + this.requisitionId);
          this.toast('Requisition created successfully', false);
          await this.fetchData();
        } else {
          // FIXME - important log to track this error
          this.toast('Error while creating requisition', true);
        }
      } else {
        this.toast('Error while creating requisition', true);
      }
      this.setAppLoading(false);
      return;
    }
  }

  public async deleteNoteHandler(id: string) {
    await dispatchDeleteNote(this.$store, id);
    const result = await dispatchGetRequisitionById(this.$store, this.requisitionId);
    if (result) {
      this.toast('Note deleted successfully');
      this.editingRequisition = result as IRequisition;
    } else {
      // error while fetching requisition, we proceed to create a new one
      this.toast('Error while fetching requisition', true);
    }
  }

  public async updateRequisition() {
    try {
      this.setAppLoading(true);
      await this.saveNotesContent();
      if (await this.$validator.validateAll()) {
        const updateRequisitionPayload: IUpdateRequisition = {};
        if (!this.editingRequisition) {
          throw new Error('No requisition to update');
        }

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'requisitionState',
          this.state,
          this.editingRequisition.requisitionState,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'confirmation',
          this.confirmation,
          this.editingRequisition.confirmation,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'dateTimeConfirmed',
          this.dateTimeConfirmedUTC,
          this.editingRequisition.dateTimeConfirmed,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'referringPhysicianId',
          this.referringPhysicianId,
          this.editingRequisition.referringPhysicianId,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'reviewed',
          this.reviewed,
          this.editingRequisition.reviewed,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'reviewingPhysicianId',
          this.reviewingPhysicianId,
          this.editingRequisition.reviewingPhysicianId,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'dateTimeReviewed',
          this.dateTimeReviewedUTC,
          this.editingRequisition.dateTimeReviewed,
        );

        if (this.objectChanged(this.patientNotes, this.editingRequisition.patientNotes)) {
          updateRequisitionPayload.patientNotes = this.patientNotes;
        }

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'allResultsAvailable',
          this.allResultsAvailable,
          this.editingRequisition.allResultsAvailable,
        );

        this.setFieldIfChanges(
          updateRequisitionPayload,
          'readyForConfirmation',
          this.readyForConfirmation,
          this.editingRequisition.readyForConfirmation,
        );

        if (updateRequisitionPayload && Object.keys(updateRequisitionPayload).length > 0) {
          const result = await dispatchUpdateRequisition(this.$store, {
            requisitionId: this.requisitionId,
            requisition: updateRequisitionPayload,
          });

          const updatedRequisition = result as IRequisition;
          if (updatedRequisition) {
            this.editingRequisition = updatedRequisition;
            this.patientProfile = updatedRequisition.patient;
            this.toast('Requisition updated successfully', false);
            await this.$nextTick();
            return Promise.resolve(true);
          } else {
            this.toast('Error while updating requisition', true);
            throw new Error('Error while updating requisition');
          }
        }
      }
    } catch (error) {
      this.toast('Error while updating requisition', true);
      return Promise.resolve(false);
    } finally {
      this.setAppLoading(false);
    }
  }

  public editVisit(visit) {
    this.editingVisit = {...visit};
    this.showVisitForm = true;
    // add the visit id as query param
    window.history.pushState({}, '', `${this.$route.path}?visitId=${visit.id}`);
  }

  public async visitUpdated(visit: IVisit) {
    // find visit by id and replace it with the updated one
    if (this.editingRequisition && Object.keys(this.editingRequisition).length > 0) {
      const visitIndex = this.assignedVisits.findIndex((v: IVisit) => v.id === visit.id);
      if (visitIndex > -1) {
        this.assignedVisits[visitIndex] = visit;
        this.editingRequisition.visits = [...this.assignedVisits];
      }
    }
    this.cancelVisitUpdateOrCreation();
  }

  public cancelVisitUpdateOrCreation() {
    this.editingVisit = null;
    this.showVisitForm = false;
    window.history.pushState({}, '', `${this.$route.path}`);
  }

  /**
   * Deletes a visit from the requisition
   * and PATCHES the requisition with the changes and then
   * DELETEs the visit from the database
   */
  public async deleteVisit(visitID) {
    try {
      if (!visitID) {
        throw new Error('Visit ID is required');
      }

      this.setAppLoading(true);

      // remove the visit from the list of Visits
      this.assignedVisits = this.assignedVisits.filter((visit) => {
        return visit.id !== visitID;
      });

      // update the requisition with the visits without the deleted visit
      const updateRequisitionPayload: IUpdateRequisition = {};
      updateRequisitionPayload.visits = this.getVisitsIdsFromVisits(this.assignedVisits);

      const requisitionUpdateResult = await dispatchUpdateRequisition(this.$store, {
        requisitionId: this.requisitionId,
        requisition: updateRequisitionPayload,
      });

      if (requisitionUpdateResult) {
        this.editingRequisition = requisitionUpdateResult;
        this.toast('Visit removed from requisition successfully', false);
        await this.$nextTick();
        // delete the visit from the database
        const deleteVisitResult = await dispatchDeleteVisit(this.$store, {
          visitId: visitID,
        });

        if (deleteVisitResult) {
          this.toast('Visit deleted successfully', false);
        } else {
          throw new Error(
            'Error while deleting visit, Visit is already removed from requisition, the visit will likely still be in the Data base',
          );
        }
      } else {
        throw new Error(
          '[AdminRequisition] Error while removing visit from requisition, server error',
        );
      }
    } catch (ex) {
      // FIXME - important log to track this error
      this.toast('Error while deleting visit', true);
    } finally {
      this.setAppLoading(false);
    }
  }

  /**
   * Creates a new visit ands adds it into the requisition
   * The new visit will be POSTed into the visits endpoint and
   * then PATCHed into the requisition endpoint
   * If something goes wrong, it will display an error message
   * and DELETE the visit from the requisition and from the server
   * It returns the created visit if everything went well or false if something went wrong
   */
  public async createVisit(visit: ICreateVisit) {
    try {
      this.setAppLoading(true);

      if (visit && this.editingRequisition) {
        // visit should have patient id, and state
        if (visit.patientId && visit.state) {
          const createVisitPaylod = {
            patientId: visit.patientId,
            state: visit.state,
          } as ICreateVisit;

          const creationResult = await dispatchCreateVisit(this.$store, createVisitPaylod);

          if (creationResult && Object.keys(creationResult).length > 0) {
            const createdVisit = creationResult as IVisit;
            if (createdVisit && createdVisit.id) {
              this.assignedVisits = [...this.assignedVisits, createdVisit];
              this.editingRequisition.visits = [...this.assignedVisits];

              // update the requisition with the new created visit
              const updateRequisitionPayload: IUpdateRequisition = {};
              updateRequisitionPayload.visits = this.getVisitsIdsFromVisits(this.assignedVisits);

              const requisitionUpdateResult = await dispatchUpdateRequisition(this.$store, {
                requisitionId: this.requisitionId,
                requisition: updateRequisitionPayload,
              });

              if (requisitionUpdateResult && requisitionUpdateResult.id) {
                this.editingRequisition = requisitionUpdateResult;
                this.showVisitForm = false;
                window.history.pushState({}, '', `${this.$route.path}`);
                this.toast('Requisition updated successfully', false);
                return Promise.resolve(createdVisit);
              } else {
                this.toast('Error while updating requisition with the new visit', true);
                await this.$nextTick();
                const deleteResult = await dispatchDeleteVisit(this.$store, {
                  visitId: createdVisit.id!,
                });

                if (deleteResult) {
                  this.assignedVisits = [
                    ...this.assignedVisits.filter((v) => v.id !== createdVisit.id),
                  ];
                  this.editingRequisition.visits = [...this.assignedVisits];
                  // FIXME - important log to track this error
                  // Visit was succesfully deleted
                  this.toast('Visit was deleted from the DB', true);
                  return Promise.resolve(false);
                } else {
                  throw new Error(`[AdminRequisition] Failed while deleting visit from the server due to a previous error.
                   Visit will still be in the DB.`);
                }
              }
            } else {
              throw new Error(
                '[AdminRequisition] Error while creating visit, no id Available, unable to remove visit from DB \n' +
                  JSON.stringify(creationResult),
              );
            }
          } else {
            throw new Error('[AdminRequisition] Failed while creating Visit, error from server');
          }
        } else {
          throw new Error(`[AdminRequisition] - Failed to create visit, missing visit.patient, visit.patientId, or visit.state
          ${JSON.stringify(visit)}`);
        }
      }
    } catch (ex) {
      this.toast(`Something went wrong, we couldn't create the visit` + ex, true);
      return Promise.resolve(false);
      // FIXME - important log to track this error
    } finally {
      this.setAppLoading(false);
    }
  }

  public cancel() {
    this.$router.back();
  }

  public onNoteInput(note: INote) {
    this.changedNotes.add(note.id);
  }

  public created() {
    dispatchGetCategories(this.$store);
  }

  private categoryToEmptyNote(category: ICategory | null): INote {
    return {
      id: '',
      user: {},
      category,
      physicianNoteLedgers: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }

  private async saveNotesContent() {
    const user = readUserProfile(this.$store);
    if (Array.isArray(this.$refs.editor)) {
      return Promise.all(
        this.$refs.editor
          .map((editorRef: any) => {
            if (this.editingRequisition?.id && user) {
              const [noteId, categoryId] = editorRef.id.split('|');

              const value = editorRef.value.trim();
              const isCreation = !noteId && !!value.length;

              let dispatchUpsertNote;
              if (isCreation) {
                dispatchUpsertNote = dispatchCreateNote;
              } else {
                dispatchUpsertNote = dispatchUpdateNote;
              }

              const noteContentChanged = this.changedNotes.has(noteId);

              const notePayload = {} as INoteInput;
              if (isCreation) {
                notePayload.requisitionId = this.editingRequisition.id;
                if (!!categoryId) { notePayload.categoryId = categoryId; }
              }

              if (isCreation || noteContentChanged) {
                notePayload.content = value;
              }

              if (dispatchUpsertNote && Object.keys(notePayload).length) {
                const result = dispatchUpsertNote(this.$store, {
                  id: noteId,
                  ...notePayload,
                });
                this.changedNotes.delete(noteId);
                return result;
              }

              return false;
            }
            return false;
          })
          .filter((i) => i),
      )
        .then(() => true)
        .catch(() => false);
    }
    return true;
  }

  private sortNoteByCategoryName(a: INote, b: INote) {
    // checking if category is null then put it at the end, otherwise sort by category name
    if (!a.category?.id && !b.category?.id) {
      return 0;
    } else if (!a.category?.id) {
      return 1;
    } else if (!b.category?.id) {
      return -1;
    } else {
      return a.category.categoryName?.localeCompare(b.category?.categoryName || '') || 0;
    }
  }
}
